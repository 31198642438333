// 这里存储所有可能会调整的配置，比如所有选择题的类型,

// 所有选择题的类型
export const checkArr = ['1', '2', '3', '4', "9", '13', '14', '15']

// 添加http前缀
export const addHeader = (val) => {
  return 'https://sunraytiku.oss-cn-beijing.aliyuncs.com/' + val
}
// 拼接图片服务器地址
export function addAllHeader (value) {

  //判断value是否数组
  if (Array.isArray(value)) {
    value = value[0]
  }
  // console.log(value)
  if (value.indexOf('./') != -1) {
    value = value.substr(2, value.length - 1)
    return 'https://sunraytiku.oss-cn-beijing.aliyuncs.com/' + value
  }
  else if (value.indexOf('https://sunraytiku.oss-cn-beijing.aliyuncs.com/') == -1 && value.indexOf('https://sunraydtk.oss-cn-beijing.aliyuncs.com/') == -1) {
    
    return 'https://sunraytiku.oss-cn-beijing.aliyuncs.com/' + value
  } else {
    return value
  }
}